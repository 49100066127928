<!--数据可视化大屏-->
<template>
  <dataz />
</template>

<script>
import dataz from './bigscreen/components/dataz/index.vue'

export default {
  name: 'dashboard',
  components: { dataz },
  data() {
    return {

    }
  },
  computed: {

  },
  created() {
  }
}
</script>
<style lang="scss">
#data-view {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0
}
</style>
