<!--数据可视化大屏组件-->
<template>
  <div id="data-view" :style="{'background':isChengdu?'linear-gradient(#131E36, #222F4A)':'linear-gradient(to bottom, #113671, #006DCB, #012C75)'}">

    <!--大屏顶部-->
    <top-header @cityChange="getAreaCode"/> 

    <!--定义了大屏的加载动画-->
    <dv-loading v-if="loading">Loading...</dv-loading>
    <div class="main-content" v-else>
      <p class="back" @click.stop="backTop" :style="{'color': dataObj.level === 'country'|| dataObj.level === 'province' || dataObj.level ==='city' ? '#ffffff':'#616161' }" v-show="this.boardDataStack.length > 1">返回</p>
      
      <div class="block-left-content" :style="{'background-color': dataObj.level === 'country'|| dataObj.level === 'province' || dataObj.level ==='city' ? '':color }">
        <!--大屏关键指标  在线及达标情况-->
        <key-indicator
          :sum-data="onlineDeviceCount"
          :event-data="handleData"
          :hour-data="hourData"
          :un-locale-number="unLocaleNumber"
          @type-change="handleTypeChange"
        />

        <!--        大屏左侧油烟健康码-->
        <health-code
          :sum-data="streetData"
          @type-change="handleTypeChange"
        />
        <!--        大屏左侧报警信息-->
        <template v-if="isChengdu">
          <alarm-message :county-data="recodeData"/>
        </template>
        <template v-else>
          <alarm-message :county-data="noticeData"/>
        </template>
      </div>

      <!--   弹出窗体 左侧排行-->
      <key-indicator-rank
        :sum-data="streetData"
        :event-data="handleData"
        :type.sync='type'
        :show="leftShow"
        @handle-close="handleCancelClick"
        class="block-left-rank"
      />


      <div class="block-center-content">
        <!--   地图上方监测点数、在线数、达标数、超标数 -->
        <data-panel-center
          :sum-data="totalData"
          :data-obj="dataObj"
          @cityChange="getAreaCode"
          @status-change="handleStatusChange"
        />
        <!--   地图中间点位展示 -->
        <center
          ref="centerChild"
          :board-list="topListData"
          :sum-data="streetData"
          :online-device="onlineDeviceData"
          :top-list="topListData"
          :content-data="dataObj"
          :county-level="countyLevelData"
          @cityChange="getAreaCode">
        </center>
      </div>
      <div class="block-right-content" :style="{'background-color': dataObj.level === 'country'|| dataObj.level === 'province' || dataObj.level ==='city' ? '':color }">
        
        <!--   地图右侧减排情况 -->
        <template v-if="isChengdu">
          <key-maintain :sum-data="maintainData"  @type-change='handleReduceChange' />
        </template>
        <template v-else>
          <reduce-data :sum-data="sumDataDaily" @type-change='handleReduceChange' />
        </template>

        <!--   报警次数对比 区域数据对比 -->
        <template v-if="dataObj.level === 'street'">
          <template v-if="isChengdu">
            <el-row style="font-size: 16rem;font-weight:bold;margin-top:10rem;padding-bottom: 10rem;">
              <div style="font-size: 16rem;font-weight:bold;float:left;  display: flex;align-items: center;height:40rem;"><img style="width:20rem;margin-right: 3rem;padding-left:10rem;" src="./img/left.png" /><span>事件列表</span></div>
            </el-row>
            <event-data  :event-data="handleData"/>
          </template>
          <template v-else-if="isAnji">
            <alarm-compare :county-data="onlineDeviceData"/>
            <event-anji-data  :event-data="handleData"/>
          </template>
          <template v-else>
            <alarm-compare :county-data="onlineDeviceData"/>
            <event-message @showLocale="showCircle" :county-data="eventData"/>
          </template>
        </template>
        <template v-else>
          <template v-if="isChengdu">
            <alarm-compare :county-data="countyLevelData"  :street-data="streetData"/>
            <region-compare
              :online-device-data="onlineDeviceData"
              :top-list-data="topListData"
              :street-data="streetData"
              :content-data="dataObj"
            />
          </template>
          <template v-else-if="isAnji">
            <region-anji-compare
              :online-device-data="onlineDeviceData"
            />
            <alarm-anji-compare :county-data="countyLevelData"  :street-data="streetData"/>
            <event-anji-data  :event-data="handleData"/>
          </template>
          <template v-else>
            <alarm-compare :county-data="countyLevelData"/>
            <region-compare
              :online-device-data="onlineDeviceData"
              :top-list-data="topListData"
              :street-data="streetData"
              :content-data="dataObj"
            />
          </template>
        </template>
      </div>

      <!--  右侧弹窗减排累计排名-->
      <reduce-rank
        :sum-data="isChengdu?maintainData:sumDataDaily"
        :type.sync='reduceType'
        :show="rightShow"
        @handle-close="handleRightCancelClick"
      />
    </div>
  </div>
</template>

<script>
import topHeader from "./topHeader";
import regionCompare from "./regionCompare"
import regionAnjiCompare from "./regionAnjiCompare"
import DataPanelCenter from "@/view/bigscreen/components/dataz/DataPanelCenter";
import SubDistrictInfoPanel from "@/view/bigscreen/components/dataz/SubDistrictInfoPanel";
import onlinePlatform from './onlinePlatform'
import centerReachRate from './centerReachRate'
import dotMap from './sctterMap'
import dotMapDistrict from './sctterMapQu'
import {loadData, stg,getUserInfo} from "@/util/index";
import {mapState} from 'vuex'
import KeyIndicator from "@/view/bigscreen/components/dataz/keyIndicator";
import KeyMaintain from "@/view/bigscreen/components/dataz/keyMaintain";
import HealthCode from "@/view/bigscreen/components/dataz/healthCode";
import AlarmMessage from "@/view/bigscreen/components/dataz/alarmMessage";
import EventMessage from "@/view/bigscreen/components/dataz/eventMessage";
import EventData from "@/view/bigscreen/components/dataz/eventData";
import EventAnjiData from "@/view/bigscreen/components/dataz/eventAnjiData";
import ReduceData from "@/view/bigscreen/components/dataz/reduceData";
import AlarmCompare from "@/view/bigscreen/components/dataz/alarmCompare";
import AlarmAnjiCompare from "@/view/bigscreen/components/dataz/alarmAnjiCompare";
import KeyIndicatorRank from "@/view/bigscreen/components/dataz/keyIndicatorRank";
import ReduceRank from "@/view/bigscreen/components/dataz/reduceRank";
import Center from "@/view/bigscreen/components/dataz/center";
import 'lib-flexible/flexible.js'

export default {
  name: "dataziew",
  components: {
    ReduceRank,
    KeyIndicatorRank,
    AlarmCompare,
    AlarmAnjiCompare,
    ReduceData,
    AlarmMessage,
    EventMessage,
    EventData,
    EventAnjiData,
    HealthCode,
    KeyIndicator,
    topHeader,
    regionCompare,
    regionAnjiCompare,
    DataPanelCenter,
    SubDistrictInfoPanel,
    onlinePlatform,
    centerReachRate,
    dotMap,
    dotMapDistrict,
    Center,
    KeyMaintain
  },
  data() {
    return {
      timer: null, // 时间对象
      loading: true,
      postdata: {
        AreaCode: '',
        areaId: '',
        level: '',
        WithSumData: true, //是否返回汇总数据,
        WithOnlineDevice: true, //返回在线设备,
        WithTopList: true, // 返回top5
      },
      dataObj: {
        level: '',
        areaId: '',
        code: ''
      },
      leftShow: false,
      rightShow: false,
      type: null,
      reduceType: null,
      topListData: null,    //排名数据，用于地图模块与区域对比模块
      onlineDeviceData: null,   //
      countyLevelData: null,    //报警信息与次数，用于报警次数对比与报警信息模块
      onlineDeviceCount: null,  //每日在线数，在线率，达标率，用于关键指标模块
      sumDataDaily: null,   //每日减排数据，用于减排情况与减排排名模块
      streetData: null,     //
      totalData: null,
      hourData: null, //小时数据分布
      eventData: null,
      recodeData: null,
      handleData: null,
      maintainData: null,
      noticeData: null,
      isChengdu: false,
      isAnji: false,
      color: "#153787",
      unLocaleNumber: 0,
    };
  },
  mounted() {
    this.getAreaCode(); // 获取数据
    loadData(this)
    this.user = getUserInfo()
    if (this.user.Info && this.user.Info.pid && this.user.Info.pid.slice(0,6) == "/1199/") {
      this.isChengdu = true
      this.color = "#131E36";
    }
    if (this.user.Info  && (this.user.Info.id == 688 || (this.user.Info.pid && this.user.Info.pid.slice(0,5) == "/688/"))) {
      this.isAnji = true
    }
  },
  beforeDestroy() {
    this.$store.commit('clearBoardDataStack')
  },
  watch: {
    postdata: {
      handler: function () {
        this.getData(true)
      },
      deep: true
    },
  },
  computed: {
    ...mapState(['boardDataStack'])
  },
  methods: {
    backTop() {
      this.bigScreenLoading = true
      const len = this.boardDataStack.length
      if (len > 1) {
        this.postdata.AreaCode = this.boardDataStack[len - 2].code
        this.postdata.level = this.boardDataStack[len - 2].level
        this.$store.commit('popBoardDataStack')
        this.getData(false)
        this.bigScreenLoading = false
      }
    },
    /**
     * @description 获取数据
     */
    initParam(param) {
      this.loading = false;
      let payload = null
      if (param) {
        payload = param
        this.postdata = {...this.postdata, ...param}
      } else {
        payload = {...this.postdata}
      }
      this.postdata.areaId = parseInt(stg().getItem('areaId'), 10)
      this.dataObj.level = this.postdata.level
      this.dataObj.areaId = this.postdata.areaId
      this.dataObj.code = this.postdata.AreaCode
    },
    async getData(type) {
      this.onlineDeviceData = null
      this.topListData = null
      this.countyLevelData = null
      this.onlineDeviceCount = null
      this.hourData = null
      this.sumDataDaily = null
      this.streetData = null
      this.totalData = null
      this.eventData = null
      this.recodeData = null
      this.handleData = null
      this.maintainData = null
      this.noticeData = null
      this.unLocaleNumber = 0
      if(this.postdata.level != "street") {
        this.$post("admin/listBoardTopList", this.postdata).then(res => {
          this.topListData = res
        })
      } else {
        this.$post("admin/listEventList", this.postdata).then(res => {
          this.eventData = res
        })
      }
      this.$post("admin/liststreetData", this.postdata).then(res => {
        this.streetData = res.sumData
        for(var i = 0; i < res.sumData.streetData.length; i++) {
          this.unLocaleNumber = this.unLocaleNumber + res.sumData.streetData[i].UnlocaleCount
        }
      })
      this.$post("admin/listBoardOnlineDevice", this.postdata).then(res => {
        this.onlineDeviceData = res
      })
      if (this.isChengdu) {
        this.$post("admin/listEventData", this.postdata).then(res => {
          this.handleData = res
        })
        this.$post("admin/listMaintenanceData", this.postdata).then(res => {
          this.maintainData = res
        })
        this.$post("admin/listRecodeData", this.postdata).then(res => {
          this.recodeData = res
        })
      } else {
        if (this.isAnji) {
          this.$post("admin/listEventData", this.postdata).then(res => {
            this.handleData = res
          })
        }
        this.$post("admin/listsumDataDaily", this.postdata).then(res => {
          this.sumDataDaily = res.sumData
        })
        this.$post("admin/listOwnerAlarmMsg", this.postdata).then(res => {
          this.noticeData = res.alarm_msg_data
        })
      }
      this.$post("admin/listonlineDeviceCountByDate", this.postdata).then(res => {
        this.onlineDeviceCount = res.sumData
      })

      this.$post("admin/listsumDataTotal", this.postdata).then(res => {
        this.totalData = res.sumData
      })

      this.$post("admin/listDeviceCountByHourTopList", this.postdata).then(res => {
        this.hourData = res
      })

      this.$post("admin/countyLevelData", this.postdata).then(res => {
        this.countyLevelData = res
      })

      this.dataObj = {
        code: this.postdata.AreaCode,
        level: this.postdata.level,
      }

      if (type && (this.boardDataStack.length == 0 || this.boardDataStack[this.boardDataStack.length-1].code != this.postdata.AreaCode)) {
        this.$store.commit('pushBoardDataStack', this.dataObj)
      }
    },
    showCircle(mn) {
      this.$refs.centerChild.showEventData(mn)
    },
    //父组件拿到子组件传过来的数据进行操作
    getAreaCode(data) {
      if (data && data.code) {
        //将数值类型的AreaCode转换成字符串
        this.postdata.AreaCode = data.code + ""
        this.postdata.level = data.level + ""
      } else {
        this.postdata.AreaCode = stg().getItem('areaCode')
        this.postdata.level = stg().getItem('level')
      }
      this.initParam();
    },
    handleStatusChange(type) {
      if (this.postdata.level == "street" || this.postdata.level == "district") {
        this.$refs.centerChild.showPointData(type)
      }
    },
    handleTypeChange(type) {
      if (this.postdata.level == "street" || this.postdata.level == "district") {
        if (type == "1" || type == "2" || type == "0" || type == "8") {
          this.$refs.centerChild.showPointData(type)
        }
      }
      if (type == "1") {
        type = "红码"
      } else if (type == "2") {
        type = "绿码"
      } else if (type == "8") {
        type = "黄码"
      }
      if (this.postdata.level == "street" || this.postdata.level == "district") {
        if (this.postdata.level == "district") {
          this.leftShow = true
        }
      } else {
        this.leftShow = true
      }
      if (type != "0") {
        this.type = type
      } else {
        this.leftShow = false
      }
    },
    handleReduceChange(type) {
      if (this.postdata.level != "street") {
        this.reduceType = type
        this.rightShow = true
      }
    },
    handleCancelClick() {
      this.leftShow = false
    },
    handleRightCancelClick() {
      this.rightShow = false
    }
  },
};
</script>

<style lang="scss" scoped>
#data-view {
  height: 100%;
  width: 100vw;
  color: #fff;
  .main-content {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    .back {
      position: absolute;
      left: 450rem;
      top: 100rem;
      color: #eee;
      z-index: 99999;
      cursor: pointer;
      font-size:20rem;
    }
  }

  .block-left-content,
  .block-center-content,
  .block-right-content {
    display: flex;
  }

  .block-center-content {
    position: absolute;
    box-sizing: border-box;
    width: 100vw;
    height: calc(100vh);
    left: 0;
    top: -40rem;

    .map_block {
      flex: 1;
      // margin-top: 14rem;
    }
  }

  .block-left-content,
  .block-right-content {
    width: 420rem;
    height: 86vh;
    padding: 6rem 6rem;
    opacity: 0.9;
    border-radius: 4rem;
    box-sizing: border-box;
    flex-direction: column;

  }
  .block-left-content {
    z-index: 5;
    position: absolute;
    left: 24rem;
    .large {
      height: 50%;
    }
  }

  .block-right-content {
    position: absolute;
    z-index: 4;
    right: 24rem;
    .large {
      height: 50%;
    }
  }

  .content-item:hover {
    cursor: pointer;
  }
}

.section_title_span {
  font-size: 11rem;
  margin-left: 3rem;
}
</style>
