<template>
  <div v-if="isChengdu" class="box_message_alarm">
    <div size="mini" style="display: flex;justify-content: space-between;margin-top:10rem;margin-left:10rem;">
      <el-row class="alarm-title">
        <img style="width:20rem;margin-right: 3rem;" src="./img/left.png" />报警信息
        <count-to :startVal='0'
          :endVal='length'
          :duration='length+10'
          :separator='null'
          style="color: #3a8efd;font-weight:bold;font-size: 16rem;display: inline-block;margin-left: 12rem;margin-right: 10rem;"/>
        <span>条</span>
      </el-row>
      <el-radio-group style="padding-right: 10rem;" size="mini" v-model="tabStatus">
        <el-radio-button label="1" @click.native="switchData(1)">实时报警</el-radio-button>
        <el-radio-button label="2" @click.native="switchData(2)">过去报警</el-radio-button>
      </el-radio-group>
    </div>
    
    <vue-seamless-scroll :data="alarmData" class="seamless-warp"  v-loading="dataLoading"  element-loading-background="rgb(19, 31, 54)" :class-option="optionHover"  ref="seamlessScroll">
      <div class="alarm-list">
        <div class="alarm-item" v-for="(item, index) in alarmData">
          <div style="width: 100%; padding: 5rem 15rem;min-height: 42rem;max-height: 80rem;">
            <div style="display: flex; justify-content: space-between;align-items: center;">
              <div style="font-size: 12rem;"><span style="color:red" v-if="item.EventTyp == '超标报警'">{{ item.EventTyp }}</span><span style="color:yellow" v-else>{{ item.EventTyp }}</span></div>
              <div style="color: #c7c7cc;font-size: 12rem;">{{item.LocaleName}}</div>
              <div style="color: #c7c7cc;font-size: 12rem;"><template v-if="tabStatus == 1">{{formaterHmsTime(item.StartAt)}}</template><template v-else>{{formaterTime(item.StartAt)}}</template></div>
            </div>
            <div style="display: flex; justify-content: space-between;align-items: center;font-size: 12rem;">
              <div style="color: #c7c7cc;">报警地址：{{ item.Addr }}</div>
              <div><span style="color: yellow;" v-if="item.Status==0">核实中</span><span style="color: red;" v-else-if="item.Status==1">整改中</span><span style="color: red;" v-else-if="item.Status==2">执法中</span><span style="color: green;" v-else>已完结</span></div>
            </div>
          </div>
        </div>
      </div>
    </vue-seamless-scroll>
  </div>
  <div class="alarm-message" v-else>
    <div size="mini" style="display: flex;justify-content: space-between;margin-top:10rem;">
      <el-row class="alarm-title">
        <img style="width:20rem;margin-right: 3rem;padding-left:10rem;" src="./img/notice.png" /><span style="white-space: nowrap; overflow: hidden;text-overflow:ellipsis;">报警信息</span>
        <count-to :startVal='0'
          :endVal='length'
          :duration='length+10'
          :separator='null'
          style="color: #3a8efd;font-weight:bold;font-size: 16rem;display: inline-block;margin-left: 6rem;margin-right: 6rem;"/>
        <span>条</span>
        <el-image 
        v-if="isAnji"
        style="width:20rem;height:20rem;margin-right: 3rem;padding-left:10rem;padding-top:2rem;"
        :preview-src-list="[require('./img/notice1.png'), require('./img/notice2.png')]"
        :src="require('./img/wenhao.png')">
        </el-image>
      </el-row>
      
      <el-radio-group style="padding-right: 10rem;" size="mini" v-model="tabStatus">
        <el-radio-button label="1" @click.native="switchTab(1)">实时报警</el-radio-button>
        <el-radio-button label="2" @click.native="switchTab(2)">过去报警</el-radio-button>
      </el-radio-group>
    </div>
    <vue-seamless-scroll style="margin-left: 10rem;margin-right: 10rem;" :data="alarmData" class="seamless-warp"  v-loading="dataLoading"  element-loading-background="rgb(44, 75, 147)" :class-option="optionHover"  ref="seamlessScroll">
      <div class="alarm-list">
        <div class="alarm-item" v-for="(item, index) in alarmData" :style="{'background-color': index % 2 === 0 ? 'rgba(1, 29, 54, 0.9)':'rgba(1, 29, 54, 0.4)' }">
          <div  @click="getAlarmInfo(item)" style="width: 100%; padding: 5rem 15rem;min-height: 42rem;max-height: 80rem;">
            <div style="display: flex; justify-content: space-between;align-items: center;">
              <div style="font-size: 12rem;"><span v-if="item.MsgType=='ExceedStandard'" style="color: #3f8ffd;">超标报警</span><span v-else-if="item.MsgType=='AbnormalOffline'" style="color: yellow;">异常离线报警</span><span v-else style="color: yellow;">不正常使用净化器报警</span></div>
              <div style="color: #f1f1f1;font-size: 12rem;">{{item.LocaleName}}</div>
              <div style="color: #f1f1f1;font-size: 12rem;">{{formaterTime(item.AcquitAt)}}</div>
            </div>
            <div style="color: #f1f1f1;font-size: 12rem;opacity: 1">
              报警地址：{{ item.Addr }}
            </div>
            <el-row >
              <el-col :span="12">
                <div style="color: #f1f1f1;font-size: 12rem;">消息推送状态: <span style="color: green;margin-left: 10px;">已推送</span></div>
              </el-col>
              <el-col :span="12">
                <div style="color: #f1f1f1;font-size: 12rem;">短信发送状态: <span style="color: green;margin-left: 10px;"><template v-if="item.IsSendSms == 1">已发送</template><template v-else>未发送</template></span></div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </vue-seamless-scroll>

    <div class="key-alarm-rank" v-show="showData">
      <div style="padding:2rem 12rem;font-size: 12rem;width:330rem;">
        <p style="display: flex;justify-content:space-between;height:18rem;align-items: center;">
          <span style="color:#ffffff;">{{alarmDetail.LocaleName}}</span>
          <span style="font-size: 16rem;" @click="closeAlarm()">x</span>
        </p>
        <p>
          <span style="color:#ffffff;">所属单位：</span>
          <span style="color:#c7c7cc">{{alarmDetail.Owner}}</span>
        </p>
        <p>
          <span style="color:#ffffff;">报警类型：</span>
          <span style="color:#c7c7cc"><span v-if="alarmDetail.MsgType=='ExceedStandard'" style="color: red;">超标报警</span><span v-else-if="alarmDetail.MsgType=='AbnormalOffline'" style="color: yellow;">异常离线报警</span><span v-else style="color: yellow;">不正常使用净化器报警</span></span>
        </p>
        <p>
          <span style="color:#ffffff;">报警时间：</span>
          <span style="color:#c7c7cc">{{formaterHmsTime(alarmDetail.AcquitAt)}}</span>
        </p>
        <p>
          <div v-if="alarmDetail.MsgType=='ExceedStandard'"> 
            <el-row>
              <el-col :span="8">
                <div style="color: #ffffff;">油烟浓度: <tempelate v-if="alarmDetail.EmissionsExceed == 1"> <span style="color: red;margin-left: 10px;">{{alarmDetail.Emissions}}</span></tempelate><tempelate v-else><span style="color: #c7c7cc;margin-left: 10px;">-</span></tempelate></div>
              </el-col>
              <el-col :span="8">
                <div style="color: #ffffff;">颗粒物浓度: <tempelate v-if="alarmDetail.GranuleExceed == 1"> <span style="color: red;margin-left: 10px;">{{alarmDetail.Granule}}</span></tempelate><tempelate v-else><span style="color: #c7c7cc;margin-left: 10px;">-</span></tempelate></div>
              </el-col>
              <el-col :span="8">
                <div style="color: #ffffff;">非甲烷浓度: <tempelate v-if="alarmDetail.HydrocarbonExceed == 1"> <span style="color: red;margin-left: 10px;">{{alarmDetail.Hydrocarbon}}</span></tempelate><tempelate v-else><span style="color: #c7c7cc;margin-left: 10px;">-</span></tempelate></div>
              </el-col>
            </el-row>
          </div>
          <div v-else-if="alarmDetail.MsgType=='AbnormalOffline'">
            <span style="color:#ffffff;">最近一次在线时间：</span>
            <span style="color:#c7c7cc">{{formaterHmsTime(alarmDetail.NowAt)}}</span>
          </div>
          <div v-else>
            <span style="color:#ffffff;">联动比(风机、净化器开启时间占比)：</span>
            <span style="color:#c7c7cc">{{alarmDetail.Emissions * 100 }} %</span>
          </div>
        </p>
        <p>
           <el-row>
              <el-col :span="12">
                <div style="color: #ffffff;">消息推送状态: <span style="color: green;margin-left: 10px;">已推送</span></div>
              </el-col>
              <el-col :span="12">
                <div style="color: #ffffff;">短信发送状态: <span style="color: green;margin-left: 10px;"><template v-if="alarmDetail.IsSendSms == 1">已发送</template><template v-else>未发送</template></span></div>
              </el-col>
            </el-row>
        </p>
        <p v-if="alarmDetail.Pics && alarmDetail.Pics.length > 0">
          <span style="color:#ffffff;">现场照片：</span>
        </p>
        <div style="height:60px; overflow:hidden;" v-if="alarmDetail.Pics && alarmDetail.Pics.length > 0">
          <el-image v-for="item in alarmDetail.Pics" :key="item" :src="item" :preview-src-list="alarmDetail.Pics" style="width: 60px;height: 60px;margin-left:6px;border-radius: 3px;" />
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import countTo from "vue-count-to";
import vueSeamlessScroll from 'vue-seamless-scroll'
import {
  dateFormater,
  getUserInfo
} from '@/util/index'
export default {
  name: 'alarmMessage',
  props: {
    countyData: {
      default: () => {
        return {}
      }
    }
  },
  components: {
    countTo,
    vueSeamlessScroll
  },
  data() {
    return {
      count: 5,
      length: 0,
      showData: false,
      alarmDetail: {},
      loading: true,
      tabStatus: 1,
      dataLoading: true,
      alarmData: undefined,
      isChengdu: false,
      isAnji: false,
      listData: [],
    }
  },
  created() {
    this.user = getUserInfo()
    if (this.user.Info && this.user.Info.pid && this.user.Info.pid.slice(0,6) == "/1199/") {
      this.isChengdu = true
    }
    if (this.user.Info  && (this.user.Info.id == 688 || (this.user.Info.pid && this.user.Info.pid.slice(0,5) == "/688/"))) {
      this.isAnji = true
    }
  },
  mounted() {
    this.getData()
    moment.locale('zh-cn');
  },
  watch: {
    countyData: {
      handler: function () {
        this.getData()
      },
      deep: true
    },
  },
  computed: {
    noMore() {
      return this.count >= this.alarmData.length
    },
    disabled() {
      return this.noMore || this.loading
    },
    optionHover() {
      return {
        limitMoveNum: 4,
        step: 0.25, // 数值越大速度滚动越快
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },

  methods: {
    formaterTime(time) {
      return dateFormater(time*1000, false, false)
    },
    formaterHmsTime(time) {
      return dateFormater(time*1000, true, false)
    },
    getData() {
      this.dataLoading = true
      if (this.countyData) {
        if (this.countyData.handle_recode_data) {
          this.listData = this.countyData.handle_recode_data
          this.switchData(this.tabStatus)
        } else {
          this.listData = this.countyData
          this.switchTab(this.tabStatus)
        }
        this.init();
        this.dataLoading = false
      }
    },
    switchData(type) {
      this.tabStatus = type
      const now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      now.setMilliseconds(0);
      const timestamp = now.getTime() / 1000;
      this.alarmData = []
      for (var i = 0; i < this.listData.length; i++){
        if (type == 1) {
          if (this.listData[i].StartAt >= timestamp) {
            this.alarmData.push(this.listData[i])
          }
        } else {
          if (this.listData[i].StartAt < timestamp) {
            this.alarmData.push(this.listData[i])
          }
        }
      }
      this.length = this.alarmData.length
      if (this.alarmData.length > 50 ) {
        this.alarmData = this.alarmData.slice(0, 50)
      }
    },
    switchTab(type) {
      this.tabStatus = type
      const now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      now.setMilliseconds(0);
      const timestamp = now.getTime() / 1000;
      this.alarmData = []
      for (var i = 0; i < this.listData.length; i++){
        if (type == 1) {
          if (this.listData[i].AcquitAt >= timestamp) {
            this.alarmData.push(this.listData[i])
          }
        } else {
          if (this.listData[i].AcquitAt < timestamp) {
            this.alarmData.push(this.listData[i])
          }
        }
      }
      this.length = this.alarmData.length
      if (this.alarmData.length > 50 ) {
        this.alarmData = this.alarmData.slice(0, 50)
      }
    },
    init() {
      if (this.length === 0) {
        return false
      }
      this.optionHover.limitMoveNum = this.alarmData.length
      this.$refs.seamlessScroll.reset()
    },
    getAlarmInfo(item) {
      this.showData = true
      this.alarmDetail = item
    },
    closeAlarm() {
      this.showData = false
    }
  }
}
</script>

<style lang="scss">
.alarm-message {
  box-sizing: border-box;
  width: 100%;
  z-index: 5;
  overflow: hidden;
  font-size: 16rem;
  margin-top: 6rem;
  .el-radio-button__inner {
    width:80rem;
    font-size: 12rem;
    padding: 7rem 15rem;
  }
}

.alarm-title {
  display: flex;
  align-items: center; 
  font-size: 16rem;
  font-weight:bold;
  margin-bottom: 10rem;
}

.seamless-warp {
  overflow: hidden;
  height: 33vh;
  .alarm-list {
    .alarm-item {
      width: auto;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
    }
  }
}

.box_message_alarm{
  background: linear-gradient(#19253e, #223e61);  
  border-radius:16px;
  box-sizing: border-box;
  width: 100%;
  z-index: 5;
  overflow: hidden;
  font-size: 16rem;
  margin-top: 6rem;
  .el-radio-button__inner {
    width:80rem;
    font-size: 12rem;
    padding: 7rem 15rem;
  }
  .el-radio-button__inner:focus.is-checked,
  .el-radio-button__inner.is-checked {
    border-color: #104D88 !important; /* 修改边框颜色 */
    box-shadow: -1px 0 0 0 #104D88 inset !important; /* 修改内阴影颜色 */
    color: #104D88 !important; /* 修改文本颜色 */
    border: 1px solid #42bddc !important;
  }
  
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: #104D88 !important; /* 修改背景颜色 */
    border-color: #104D88 !important; /* 修改边框颜色 */
     border: 1px solid #42bddc !important;
  }
  .el-radio-button__inner {
    background: #131E36 !important;
    border: 1px solid #42bddc !important;
    color: #fff;
  }
  /deep/ .el-input__inner {
    color: #fff!important;
    border: 1px solid #326AFF;
    background: #04308D !important;
  }
}
.box_message_alarm:hover {
  box-shadow: inset 0 0 32px #25a6ff;
}

.key-alarm-rank {
  position: absolute;
  left: 445rem;
  top: 52.4vh;
  z-index: 8;
  background: rgba(21, 55, 135, 1);
  padding-bottom:6px;
}

</style>
